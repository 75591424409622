@font-face {
  font-family: 'Lato';
  src: url('/assets/fonts/Lato-Regular.eot');
  src: url('/assets/fonts/Lato-Regular.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/Lato-Regular.woff2') format('woff2'), url('/assets/fonts/Lato-Regular.woff') format('woff'), url('/assets/fonts/Lato-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility; }

@font-face {
  font-family: 'Lato';
  src: url('/assets/fonts/Lato-Bold.eot');
  src: url('/assets/fonts/Lato-Bold.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/Lato-Bold.woff2') format('woff2'), url('/assets/fonts/Lato-Bold.woff') format('woff'), url('/assets/fonts/Lato-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: bold;
  text-rendering: optimizeLegibility; }

@font-face {
  font-family: 'Lato';
  src: url('/assets/fonts/Lato-BoldItalic.eot');
  src: url('/assets/fonts/Lato-BoldItalic.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/Lato-BoldItalic.woff2') format('woff2'), url('/assets/fonts/Lato-BoldItalic.woff') format('woff'), url('/assets/fonts/Lato-BoldItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: bold;
  text-rendering: optimizeLegibility; }

@font-face {
  font-family: 'Lato';
  src: url('/assets/fonts/Lato-Italic.eot');
  src: url('/assets/fonts/Lato-Italic.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/Lato-Italic.woff2') format('woff2'), url('/assets/fonts/Lato-Italic.woff') format('woff'), url('/assets/fonts/Lato-Italic.ttf') format('truetype');
  font-style: italic;
  font-weight: normal;
  text-rendering: optimizeLegibility; }
